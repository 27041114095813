import React, {useState, useRef, useEffect, useCallback} from 'react';
import {
  Image,
  SafeAreaView,
  DeviceEventEmitter,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  TextInput,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  ActivityIndicator,
} from 'react-native';
import styles from '../common/styles';
import Colors from '../common/colors';
import {Shadow} from 'react-native-shadow-2';
import TeamItem from '../component/TeamItem';
import CreateTeamPopup from '../component/CreateTeamPopup';
import SelectDropdown from 'react-native-select-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import MyGameScheduleList from '../component/MyGameScheduleList';
import {debounce} from 'lodash';

const TeamView = ({navigation}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [createPopupOpened, setCreatePopupOpened] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [userKeyword, setUserKeyword] = useState('');
  const [topRegions, setTopRegions] = useState([]);
  const [secondRegions, setSecondRegions] = useState([]);
  const [myTeamPage, setMyTeamPage] = useState([]);
  const [teamPage, setTeamPage] = useState([]);
  const [memberPage, setMemberPage] = useState([]);
  const [parentRegionId, setParentRegionId] = useState(null);
  const [regionId, setRegionId] = useState(null);
  const [alarmUnread, setAlarmUnread] = useState(false);

  const [userInfo, setUserInfo] = useState({});
  let accessToken = useRef();
  let teamPageLoading = useRef();
  let memberPageLoading = useRef();
  const [progressShown, setProgressShown] = useState(false);

  const tabList = [
    {index: 0, text: '내 소속'},
    {index: 1, text: '팀찾기'},
    {index: 2, text: '선수검색'},
    {index: 3, text: '내 일정'},
  ];
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
      getUserInfo(result);
    });

    const unsubscribe = DeviceEventEmitter.addListener(
      'teamTabInfoRefresh',
      function (data) {
        getData(accessToken.current);
        checkAlarmUnRead();
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  useEffect(() => {
    getRegionData(parentRegionId);
    handleFilterChange();
  }, [parentRegionId]);

  useEffect(() => {
    handleFilterChange();
  }, [regionId]);

  const debounceSearchTeam = useCallback(
    debounce(recentKeyword => {
      handleFilterChange(recentKeyword);
    }, 250),
    [],
  );

  useEffect(() => {
    debounceSearchTeam(keyword);
  }, [keyword]);

  const debounceSearchMember = useCallback(
    debounce(recentUserKeyword => {
      memberPageLoading.current = false;
      getMember(recentUserKeyword);
    }, 250),
    [],
  );

  useEffect(() => {
    if (!userKeyword) {
      setMemberPage([]);
      return;
    }
    debounceSearchMember(userKeyword);
  }, [userKeyword]);

  const requestCertify = () => {
    console.log('requestCertify in TeamView');
    /* 2. 본인인증 데이터 정의하기 */
    const data = {
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      company: '(주) 유니크플레이', // 회사명 또는 URL
    };
    navigation?.navigate('Certification', {
      userCode: 'imp66015233',
      // userCode: 'iamport',
      data: data,
      accessToken: accessToken.current,
      callback: {
        screen: 'Main',
        data: {},
      },
    });
  };

  const checkAlarmUnRead = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/users/exists-unread-alarm', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setAlarmUnread(response.data);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const getUserInfo = token => {
    api
      .get('/app-user/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setUserInfo(response.data);
        setAlarmUnread(response.data.alarmRead);
        console.log('userInfo', response.data);
        if (!response.data.certificationId) {
          console.log('in TeamView');
          requestCertify();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getMyTeams = token => {
    setProgressShown(true);
    api
      .get('/app-team/my-teams', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setMyTeamPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
  };

  const getData = token => {
    getMyTeams(token);
    api
      .get(
        `/app-team/filter?regionId=${
          regionId && regionId ? regionId : ''
        }&parentRegionId=${
          parentRegionId && parentRegionId ? parentRegionId : ''
        }&keyword=${keyword ? keyword : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setTeamPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
    api
      .get('/regions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setTopRegions([{id: null, sido: '전체지역'}, ...response.data]);
        setSecondRegions([{id: null, sigungu: '전체지역'}]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getRegionData = parentId => {
    api
      .get(`/regions?parentId=${parentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setSecondRegions([{id: null, sigungu: '전체지역'}, ...response.data]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFilterChange = recentKeyword => {
    setProgressShown(true);
    api
      .get(
        `/app-team/filter?regionId=${
          regionId && regionId ? regionId : ''
        }&parentRegionId=${
          parentRegionId && parentRegionId ? parentRegionId : ''
        }&keyword=${recentKeyword ? recentKeyword : keyword}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setTeamPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
      });
  };

  const handleTeamPageLoadMore = () => {
    if (teamPageLoading.current) {
      return;
    }
    if (teamPage.currentPage && teamPage.totalPage <= teamPage.currentPage) {
      return;
    }
    teamPageLoading.current = true;
    setProgressShown(true);
    api
      .get(
        `/app-team/filter?regionId=${
          regionId && regionId ? regionId : ''
        }&parentRegionId=${
          parentRegionId && parentRegionId ? parentRegionId : ''
        }&keyword=${keyword}&pageNo=${
          teamPage.currentPage ? parseInt(teamPage.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(teamPage.items ? teamPage.items : []),
          ...response.data.items,
        ];
        setTeamPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        teamPageLoading.current = false;
        setProgressShown(false);
      });
  };

  const getMember = recentUserKeyword => {
    if (memberPageLoading.current) {
      return;
    }
    if (!recentUserKeyword && !userKeyword) {
      return;
    }
    setProgressShown(true);
    memberPageLoading.current = true;
    api
      .get(
        `/app-team/members?keyword=${
          recentUserKeyword ? recentUserKeyword : userKeyword
        }&pageNo=1&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setMemberPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        memberPageLoading.current = false;
      });
  };

  const handleMemberPageLoadMore = () => {
    if (memberPageLoading.current) {
      return;
    }
    if (
      memberPage.currentPage &&
      memberPage.totalPage <= memberPage.currentPage
    ) {
      return;
    }
    if (!userKeyword) {
      return;
    }
    setProgressShown(true);
    memberPageLoading.current = true;
    api
      .get(
        `/app-team/members?keyword=${userKeyword}&pageNo=${
          memberPage.currentPage ? parseInt(memberPage.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(memberPage.items ? memberPage.items : []),
          ...response.data.items,
        ];

        setMemberPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        memberPageLoading.current = false;
      });
  };

  const getProfileImageUrl = item => {
    if (item?.profileImageThumbUrl) {
      return {uri: item?.profileImageThumbUrl};
    }
    if (item?.profileImageUrl) {
      return {uri: item?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  };

  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={pageStyle.container}>
        <SafeAreaView style={[styles.container]}>
          <Shadow
            offset={[0, 2]}
            style={[{width: '100%'}]}
            containerStyle={[{marginTop: 0}]}
            distance={7}
            sides={{bottom: true, top: false, start: false, end: false}}>
            <View style={[styles.headerCommon]}>
              <View style={[styles.row, styles.verticalAlign]}>
                <TouchableOpacity
                  onPress={() =>
                    Platform.OS === 'web' ? history.back() : navigation.goBack()
                  }>
                  <Image
                    source={require('../assets/images/icon_back.png')}
                    style={[styles.headerBackButton]}
                  />
                </TouchableOpacity>
                <Text style={[styles.headerText]}>팀페이지</Text>
              </View>
              <View style={[styles.row, styles.verticalAlign]}>
                <TouchableOpacity
                  style={[pageStyle.createTeamBtn]}
                  onPress={() => setCreatePopupOpened(true)}>
                  <Text style={[pageStyle.createTeamBtnTxt]}>팀 생성</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.alarmContainer]}
                  onPress={() => navigation.navigate('MyAlarmList')}>
                  <Image
                    resizeMode="contain"
                    style={[styles.imgAlarm]}
                    source={require('../assets/images/icn_alarm.png')}
                  />
                  {alarmUnread && <View style={[styles.alarmDot]} />}
                </TouchableOpacity>
              </View>
            </View>
          </Shadow>
          <View style={[{marginTop: 10}]}>
            <View
              style={[
                styles.scrollViewContainer,
                pageStyle.scrollViewContainer,
              ]}>
              <FlatList
                style={[styles.tabContainer]}
                showsHorizontalScrollIndicator={Platform.OS === 'web'}
                horizontal={true}
                data={tabList}
                renderItem={({item}) => {
                  return (
                    <TouchableOpacity
                      onPress={() => setTabIndex(item.index)}
                      style={[
                        tabIndex === item.index && styles.tabItemSelected,
                        tabIndex !== item.index && styles.tabItem,
                        tabList.length === item.index + 1 && {marginEnd: 20},
                      ]}>
                      <Text
                        style={[
                          tabIndex === item.index && styles.tabItemTextSelected,
                          tabIndex !== item.index && styles.tabItemText,
                        ]}>
                        {item.text}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
              {tabIndex === 0 && (
                <FlatList
                  nestedScrollEnabled={true}
                  style={[pageStyle.list]}
                  keyExtractor={item => item.id.toString()}
                  data={myTeamPage.items}
                  ListFooterComponent={() => <View style={[{height: 140}]} />}
                  ListEmptyComponent={() => (
                    <View style={[styles.emptyContainer]}>
                      <Image source={require('../assets/images/logo2.png')} />
                    </View>
                  )}
                  renderItem={({item}) => (
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate('TeamDetail', {
                          item: item,
                          itemId: item?.id,
                        })
                      }>
                      <TeamItem data={item} />
                    </TouchableOpacity>
                  )}
                />
              )}
              {tabIndex === 1 && (
                <View>
                  <View style={[pageStyle.dropDownContainer]}>
                    <View style={[pageStyle.dropDownItemContainer]}>
                      <SelectDropdown
                        defaultButtonText="광역시/도"
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        rowTextStyle={styles.dropDownRowText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../assets/images/select_arr.png')}
                          />
                        )}
                        data={topRegions}
                        onSelect={(selectedItem, index) => {
                          setParentRegionId(selectedItem.id);
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          return selectedItem.sido;
                        }}
                        rowTextForSelection={(item, index) => {
                          return item.sido;
                        }}
                      />
                    </View>
                    <View style={[pageStyle.dropDownItemContainer]}>
                      <SelectDropdown
                        defaultValue={{}}
                        defaultButtonText="시/군/구"
                        rowTextStyle={styles.dropDownRowText}
                        buttonStyle={pageStyle.dropDown}
                        buttonTextStyle={pageStyle.downDownText}
                        renderDropdownIcon={() => (
                          <Image
                            source={require('../assets/images/select_arr.png')}
                          />
                        )}
                        data={secondRegions}
                        onSelect={(selectedItem, index) => {
                          setRegionId(selectedItem.id);
                        }}
                        buttonTextAfterSelection={(selectedItem, index) => {
                          return selectedItem.sigungu;
                        }}
                        rowTextForSelection={(item, index) => {
                          return item.sigungu;
                        }}
                      />
                    </View>
                  </View>
                  <View style={[pageStyle.searchInputContainer]}>
                    <TextInput
                      style={[pageStyle.searchInput]}
                      placeholder={'검색'}
                      placeholderTextColor={'#aaa'}
                      value={keyword}
                      onChangeText={value => {
                        setKeyword(value);
                      }}
                    />
                    <Image
                      style={[pageStyle.searchIcon]}
                      source={require('../assets/images/icn_search.png')}
                    />
                  </View>
                  <FlatList
                    nestedScrollEnabled={true}
                    style={[pageStyle.list2]}
                    keyExtractor={item => item.id.toString()}
                    data={teamPage.items}
                    onEndReachedThreshold={1}
                    onEndReached={handleTeamPageLoadMore}
                    ListFooterComponent={() => <View style={[{height: 40}]} />}
                    ListEmptyComponent={() => (
                      <View style={[styles.emptyContainer]}>
                        <Image source={require('../assets/images/logo2.png')} />
                      </View>
                    )}
                    renderItem={({item}) => (
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate('TeamDetail', {
                            item: item,
                            itemId: item?.id,
                          })
                        }>
                        <TeamItem data={item} />
                      </TouchableOpacity>
                    )}
                  />
                </View>
              )}
              {tabIndex === 2 && (
                <View>
                  <View style={[pageStyle.searchInputContainer]}>
                    <TextInput
                      style={[pageStyle.searchInput]}
                      placeholder={'검색'}
                      placeholderTextColor={'#aaa'}
                      value={userKeyword}
                      onChangeText={value => {
                        setUserKeyword(value);
                      }}
                    />
                    <Image
                      style={[pageStyle.searchIcon]}
                      source={require('../assets/images/icn_search.png')}
                    />
                  </View>
                  <FlatList
                    nestedScrollEnabled={true}
                    style={[pageStyle.list2]}
                    keyExtractor={item => item.id.toString()}
                    data={memberPage.items}
                    onEndReachedThreshold={1}
                    onEndReached={handleMemberPageLoadMore}
                    ListFooterComponent={() => <View style={[{height: 40}]} />}
                    ListEmptyComponent={() => (
                      <View style={[styles.emptyContainer]}>
                        <Image source={require('../assets/images/logo2.png')} />
                      </View>
                    )}
                    renderItem={({item}) => (
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate('MemberPage', {
                            item: item,
                            itemId: item.uid,
                          })
                        }>
                        <View style={[styles.row, pageStyle.memberItemRow]}>
                          <View
                            style={[styles.row, pageStyle.profileContainer]}>
                            <Image
                              style={[
                                {
                                  width: 30,
                                  height: 30,
                                  resizeMode: 'cover',
                                  borderRadius: 15,
                                },
                              ]}
                              source={getProfileImageUrl(item)}
                            />
                            <Text style={[pageStyle.memberName]}>
                              {item.name}
                            </Text>
                          </View>
                          <Text style={[pageStyle.teamName]}>
                            {item.teamName}
                          </Text>
                          <Text style={[pageStyle.birth]}>
                            {!item.hideBirth ? item.birth : '비공개'}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  />
                </View>
              )}

              {tabIndex === 3 && (
                <MyGameScheduleList
                  navigation={navigation}
                  userInfo={userInfo}
                  accessToken={accessToken.current}
                />
              )}
            </View>
            {progressShown && (
              <View style={[styles.progressContainer]}>
                <Progress.Circle
                  fill="transparent"
                  size={70}
                  borderWidth={3}
                  indeterminate={true}
                />
              </View>
            )}
          </View>
        </SafeAreaView>
        {createPopupOpened && (
          <CreateTeamPopup
            navigation={navigation}
            closePopup={() => {
              setCreatePopupOpened(false);
              getMyTeams();
            }}
          />
        )}
      </KeyboardAvoidingView>
    </>
  );
};

const pageStyle = StyleSheet.create({
  container: {flex: 1},
  list: {
    marginTop: 4,
    paddingStart: 26,
    paddingEnd: 26,
    paddingTop: 10,
    height:
      Dimensions.get('window').height - (Platform.OS === 'ios' ? 150 : 200),
    paddingBottom: 20,
  },
  list2: {
    marginTop: 5,
    paddingStart: 26,
    paddingEnd: 26,
    paddingTop: 10,
    height:
      Dimensions.get('window').height - (Platform.OS === 'ios' ? 320 : 270),
    paddingBottom: 0,
  },
  createTeamBtn: {
    width: 60,
    height: 22,
    borderRadius: 12,
    backgroundColor: Colors.main,
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 14,
  },
  createTeamBtnTxt: {
    color: 'white',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  dropDownContainer: {
    flexDirection: 'row',
    paddingHorizontal: 22,
    marginTop: 10,
  },
  dropDownItemContainer: {
    marginHorizontal: 3,
    flex: 1,
  },
  dropDown: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgb(61, 80, 183)',
    height: 30,
  },
  downDownText: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 11,
  },
  dropDownRowText: {fontSize: 12, fontFamily: 'Pretendard'},
  searchInput: {
    padding: 0,
    marginTop: 11,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 13,
    fontFamily: 'Pretendard',
    fontSize: 12,
    height: 30,
    color: 'black',
    textAlign: 'center',
  },
  searchInputContainer: {
    position: 'relative',
    marginHorizontal: 27,
    backgroundColor: 'transparent',
  },
  searchIcon: {
    position: 'absolute',
    top: 18,
    left: 8,
    width: 17,
    height: 17,
  },
  memberItemRow: {
    paddingVertical: 10,
    alignItems: 'center',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  profileContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  memberName: {
    marginStart: 6,
    color: 'black',
    fontSize: 14,
    fontFamily: 'Pretendard',
  },
  teamName: {
    marginStart: 6,
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 14,
    flex: 1,
    textAlign: 'center',
  },
  birth: {
    marginStart: 6,
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 14,
    flex: 1,
    textAlign: 'center',
  },
});

export default TeamView;
