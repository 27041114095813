import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Linking,
  Platform,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import {ScrollView, TextInput} from 'react-native-gesture-handler';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import Colors from '../common/colors';
import {title} from '../common/util';
import SelectDropdown from 'react-native-select-dropdown';
import moment from 'moment';

const ExternalRecordView = ({navigation, route}) => {
  const [toggle, setToggle] = useState(1);
  const [seasonYear, setSeasonYear] = useState('');
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [data, setData] = useState({});
  let accessToken = useRef();
  let seasonRef = useRef();

  const getInning = innings => {
    let point = innings - parseInt(innings, 10);
    point = Math.round(point * 10) / 10;
    return parseInt(innings, 10) + point * 0.3333 * 10;
  };

  const getCalculateData = tempData => {
    if (!tempData) {
      return;
    }

    tempData.pgame = tempData.pgame ? Number(tempData.pgame) : 0;
    tempData.win = tempData.win ? Number(tempData.win) : 0;
    tempData.lose = tempData.lose ? Number(tempData.lose) : 0;
    tempData.save = tempData.save ? Number(tempData.save) : 0;
    tempData.hold = tempData.hold ? Number(tempData.hold) : 0;
    tempData.wrate = tempData.wrate ? Number(tempData.wrate) : 0;
    tempData.pab = tempData.pab ? Number(tempData.pab) : 0;
    tempData.ptb = tempData.ptb ? Number(tempData.ptb) : 0;
    tempData.numberOfPitches = tempData.numberOfPitches
      ? Number(tempData.numberOfPitches)
      : 0;
    tempData.innings = tempData.innings ? Number(tempData.innings) : 0;
    tempData.ph = tempData.ph ? Number(tempData.ph) : 0;
    tempData.phr = tempData.phr ? Number(tempData.phr) : 0;
    tempData.psacb = tempData.psacb ? Number(tempData.psacb) : 0;
    tempData.psacf = tempData.psacf ? Number(tempData.psacf) : 0;
    tempData.pbb = tempData.pbb ? Number(tempData.pgame) : 0;
    tempData.pibb = tempData.pibb ? Number(tempData.pibb) : 0;
    tempData.phitByPitch = tempData.phitByPitch
      ? Number(tempData.phitByPitch)
      : 0;
    tempData.era = tempData.era ? Number(tempData.era) : 0;
    tempData.whip = tempData.whip ? Number(tempData.whip) : 0;
    tempData.so = tempData.so ? Number(tempData.so) : 0;
    tempData.boke = tempData.boke ? Number(tempData.boke) : 0;
    tempData.wp = tempData.wp ? Number(tempData.wp) : 0;
    tempData.r = tempData.r ? Number(tempData.r) : 0;
    tempData.er = tempData.er ? Number(tempData.er) : 0;
    tempData.pavg = tempData.pavg ? Number(tempData.pavg) : 0;
    tempData.sorate = tempData.sorate ? Number(tempData.sorate) : 0;
    tempData.hgame = tempData.hgame ? Number(tempData.hgame) : 0;
    tempData.havg = tempData.havg ? Number(tempData.havg) : 0;
    tempData.htb = tempData.htb ? Number(tempData.htb) : 0;
    tempData.hab = tempData.hab ? Number(tempData.hab) : 0;
    tempData.rbi = tempData.rbi ? Number(tempData.rbi) : 0;
    tempData.her = tempData.her ? Number(tempData.her) : 0;
    tempData.h = tempData.h ? Number(tempData.h) : 0;
    tempData.h1 = tempData.h1 ? Number(tempData.h1) : 0;
    tempData.h2 = tempData.h2 ? Number(tempData.h2) : 0;
    tempData.h3 = tempData.h3 ? Number(tempData.h3) : 0;
    tempData.hr = tempData.hr ? Number(tempData.hr) : 0;
    tempData.totalBaseHit = tempData.totalBaseHit
      ? Number(tempData.totalBaseHit)
      : 0;
    tempData.hso = tempData.hso ? Number(tempData.hso) : 0;
    tempData.sb = tempData.sb ? Number(tempData.sb) : 0;
    tempData.sbo = tempData.sbo ? Number(tempData.sbo) : 0;
    tempData.hsacb = tempData.hsacb ? Number(tempData.hsacb) : 0;
    tempData.hsacf = tempData.hsacf ? Number(tempData.hsacf) : 0;
    tempData.hbb = tempData.hbb ? Number(tempData.hbb) : 0;
    tempData.hibb = tempData.hibb ? Number(tempData.hibb) : 0;
    tempData.hhitByPitch = tempData.hhitByPitch
      ? Number(tempData.hhitByPitch)
      : 0;
    tempData.do = tempData.do ? Number(tempData.do) : 0;
    tempData.srate = tempData.srate ? Number(tempData.srate) : 0;
    tempData.obrate = tempData.obrate ? Number(tempData.obrate) : 0;
    tempData.sbrate = tempData.sbrate ? Number(tempData.sbrate) : 0;
    tempData.muitihit = tempData.muitihit ? Number(tempData.muitihit) : 0;

    if (tempData.win + tempData.lose > 0) {
      tempData.wrate = parseFloat(
        ((1 * tempData.win) / (tempData.win + tempData.lose)).toFixed(2),
      );
    }
    if (tempData.innings && tempData.innings > 0) {
      let point = tempData.innings - parseInt(String(tempData.innings));
      point = Math.round(point * 10) / 10;
      if (point === 0) {
        tempData.era = parseFloat(
          ((tempData.er * 7) / tempData.innings).toFixed(2),
        );
      } else {
        tempData.era = parseFloat(
          ((tempData.er * 7) / getInning(tempData.innings)).toFixed(2),
        );
      }
      tempData.whip = parseFloat(
        ((tempData.ph + tempData.pbb) / getInning(tempData.innings)).toFixed(2),
      );
    }
    if (tempData.pab && tempData.pab > 0) {
      tempData.pavg = parseFloat((tempData.ph / tempData.pab).toFixed(3));
    }
    if (tempData.ptb && tempData.ptb > 0) {
      tempData.sorate = parseFloat((tempData.so / tempData.ptb).toFixed(3));
    }

    const havg =
      tempData.hab && tempData.hab > 0
        ? parseFloat((tempData.h / tempData.hab).toFixed(3))
        : '';
    const h =
      parseInt(tempData?.h1 ? tempData?.h1 : 0) +
      parseInt(tempData?.h2 ? tempData?.h2 : 0) +
      parseInt(tempData?.h3 ? tempData?.h3 : 0) +
      parseInt(tempData?.hr ? tempData?.hr : 0);
    const totalBaseHit =
      1 * parseInt(tempData?.h1 ? tempData?.h1 : 0) +
      2 * parseInt(tempData?.h2 ? tempData?.h2 : 0) +
      3 * parseInt(tempData?.h3 ? tempData?.h3 : 0) +
      4 * parseInt(tempData?.hr ? tempData?.hr : 0);
    const srate =
      totalBaseHit && tempData.hab > 0
        ? parseFloat((totalBaseHit / tempData.hab).toFixed(3))
        : '';

    if (
      tempData.hab + tempData.hbb + tempData.hhitByPitch + tempData.hsacf >
      0
    ) {
      tempData.obrate = parseFloat(
        (
          (tempData.h + tempData.hbb + tempData.hhitByPitch) /
          (tempData.hab + tempData.hbb + tempData.hhitByPitch + tempData.hsacf)
        ).toFixed(3),
      );
    }
    if (tempData.sb + tempData.sbo > 0) {
      tempData.sbrate = parseFloat(
        (tempData.sb / (tempData.sb + tempData.sbo)).toFixed(3),
      );
    }
    if (tempData.hso > 0) {
      tempData.bbk = parseFloat((tempData.hbb / tempData.hso).toFixed(3));
    }
    if (!isNaN(tempData.srate) && !isNaN(tempData.obrate)) {
      tempData.ops = tempData.srate + tempData.obrate;
    }
    tempData.oh = `${tempData.h - tempData.h1}/${tempData.h}`;
    if (tempData.h > 0) {
      tempData.ohRate = (tempData.h - tempData.h1) / tempData.h;
    }

    return {
      ...tempData,
      havg: havg,
      h: h,
      totalBaseHit: totalBaseHit,
      srate: srate,
    };
  };

  const getData = seasonYear => {
    if (!seasonYear) {
      return;
    }
    api
      .get(`/games/records/by-user?seasonYear=${seasonYear}&external=true`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        const data = response.data ? response.data : {};
        for (let i = 0; i < Object.keys(data)?.length; i++) {
          if (data[Object.keys(data)[i]]) {
            data[Object.keys(data)[i]] = data[Object.keys(data)[i]] + '';
          }
        }
        setData(data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    let tempSeasonOptions = [];
    for (let i = 2000; i <= moment().year(); i++) {
      tempSeasonOptions.push(i);
    }
    setSeasonOptions(tempSeasonOptions);
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      accessToken.current = result;
    });
  }, []);

  useEffect(() => {
    getData(seasonYear);
  }, [seasonYear]);

  const handleSave = () => {
    api
      .post(
        '/games/records/external',
        {...data, seasonYear: seasonYear},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        alert('요청에 성공했습니다.');
      })
      .catch(function (error) {
        console.log(error.response.data);
        alert('요청에 실패했습니다.');
      })
      .finally(() => {});
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>외부 기록 추가</Text>
          </View>
        </View>
      </Shadow>
      <ScrollView style={[pageStyle.container]}>
        <View style={[pageStyle.innerContainer]}>
          <Text style={[pageStyle.infoTxt]}>
            해당 페이지에서 입력하시는 기록은 기록실에서 외부기록으로 별도
            표기됩니다. (입력된 기록은 익일 재계산하여 적용됩니다.)
          </Text>
          <View style={[styles.row, pageStyle.tab]}>
            <View style={[styles.col]}>
              <SelectDropdown
                ref={seasonRef}
                defaultValue={seasonYear}
                defaultButtonText="시즌"
                buttonStyle={styles.dropDown}
                buttonTextStyle={styles.downDownText}
                rowTextStyle={styles.dropDownRowText}
                data={seasonOptions}
                onSelect={(selectedItem, index) => {
                  if (selectedItem === '전체') {
                    setSeasonYear('');
                  } else {
                    setSeasonYear(selectedItem);
                  }
                }}
                renderDropdownIcon={() => (
                  <Image source={require('../assets/images/select_arr.png')} />
                )}
              />
            </View>
            <View style={[styles.col]}>
              <View style={[pageStyle.toggleContainer]}>
                <View style={[styles.row]}>
                  <TouchableOpacity
                    onPress={() => setToggle(1)}
                    style={[
                      pageStyle.toggleItem,
                      pageStyle.toggleLeft,
                      toggle === 1 && pageStyle.toggleLeftSelected,
                    ]}>
                    <Text
                      style={[
                        pageStyle.toggleTxt,
                        toggle === 1 && pageStyle.toggleTxtSelected,
                      ]}>
                      타자
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => setToggle(0)}
                    style={[
                      pageStyle.toggleItem,
                      pageStyle.toggleRight,
                      toggle === 0 && pageStyle.toggleRightSelected,
                    ]}>
                    <Text
                      style={[
                        pageStyle.toggleTxt,
                        toggle === 0 && pageStyle.toggleTxtSelected,
                      ]}>
                      투수
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
          <View style={[pageStyle.table]}>
            {toggle === 0 && (
              <>
                <View style={[pageStyle.row, pageStyle.firstRow]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      경기수
                    </Text>
                    <TextInput
                      keyboardType="number-pad"
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          pgame: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          pgame: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                      value={data?.pgame}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      ERA
                    </Text>
                    <Text
                      keyboardType="number-pad"
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.era ? data?.era : '0.00'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      승
                    </Text>
                    <TextInput
                      keyboardType="number-pad"
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.win}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          win: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          win: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]}>패</Text>
                    <TextInput
                      keyboardType="number-pad"
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.lose}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          lose: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          lose: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      세이브
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.save}
                      keyboardType="number-pad"
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          save: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          save: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      홀드
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      keyboardType="number-pad"
                      value={data?.hold}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hold: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hold: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      승률
                    </Text>
                    <Text
                      keyboardType="number-pad"
                      style={[pageStyle.td]}
                      numberOfLines={1}>
                      {data?.wrate ? data?.wrate : '0.00'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타자
                    </Text>
                    <TextInput
                      keyboardType="number-pad"
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.ptb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          ptb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          ptb: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타수
                    </Text>
                    <TextInput
                      keyboardType="number-pad"
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.pab}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          pab: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          pab: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]}>투구수</Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      keyboardType="number-pad"
                      value={data?.numberOfPitches}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          numberOfPitches: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          numberOfPitches: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      이닝
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.innings}
                      placeholder="3.2"
                      placeholderTextColor={'#ccc'}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          innings: value.replace(/[^0-9.]/g, ''),
                        });
                        setData({
                          ...data,
                          innings: value.replace(/[^0-9.]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      피안타
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      keyboardType="number-pad"
                      value={data?.ph}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          ph: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          ph: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      피홈런
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.phr}
                      keyboardType="number-pad"
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          phr: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          phr: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      희타
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      keyboardType="number-pad"
                      value={data?.psacb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          psacb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          psacb: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      희비
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.psacf}
                      keyboardType="number-pad"
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          psacf: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          psacf: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]}>볼넷</Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      keyboardType="number-pad"
                      value={data?.pbb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          pbb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          pbb: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      고의4구
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.pibb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          pibb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          pibb: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                      keyboardType="number-pad"
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      사구
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      keyboardType="number-pad"
                      value={data?.phitByPitch}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          phitByPitch: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          phitByPitch: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      탈삼진
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.so}
                      keyboardType="number-pad"
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          so: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          so: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      폭투
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      keyboardType="number-pad"
                      value={data?.wp}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          wp: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          wp: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      보크
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.boke}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          boke: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          boke: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]}>실점</Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      keyboardType="number-pad"
                      value={data?.r}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          r: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          r: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      자책점
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.er}
                      keyboardType="number-pad"
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          er: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          er: value.replace(/[^0-9]/g, ''),
                          era: temp.era,
                          wrate: temp.wrate,
                          whip: temp.whip,
                          pavg: temp.pavg,
                          sorate: temp.sorate,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      WHIP
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.whip ? parseFloat(data?.whip).toFixed(2) : '0.00'}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      피안타율
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {data?.pavg ? parseFloat(data?.pavg).toFixed(3) : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      탈삼진율
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.sorate
                        ? parseFloat(data?.sorate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]} />
                  <View style={[pageStyle.col]} />
                  <View style={[pageStyle.col]} />
                  <View style={[pageStyle.col]} />
                </View>
              </>
            )}
            {toggle === 1 && (
              <>
                <View style={[pageStyle.row, pageStyle.firstRow]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      경기수
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.hgame}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hgame: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hgame: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타율
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.havg ? parseFloat(data?.havg).toFixed(3) : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타석
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.htb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          htb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          htb: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타수
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.hab}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hab: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hab: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      득점
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.her}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          her: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          her: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      안타수
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.h}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      1루타
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.h1}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          h1: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          h1: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      2루타
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.h2}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          h2: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          h2: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      3루타
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.h3}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          h3: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          h3: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      홈런
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.hr}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hr: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hr: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      루타
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {data?.totalBaseHit}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      타점
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.rbi}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          rbi: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          rbi: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      도루
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.sb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          sb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          sb: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      도루자
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.sbo}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          sbo: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          sbo: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      희타
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.hsacb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hsacb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hsacb: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      희비
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.hsacf}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hsacf: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hsacf: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      볼넷
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.hbb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hbb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hbb: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      고의사구
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.hibb}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hibb: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hibb: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      사구
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.hhitByPitch}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hhitByPitch: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hhitByPitch: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      삼진
                    </Text>
                    <TextInput
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                      value={data?.hso}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          hso: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          hso: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      병살
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.do}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          do: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          do: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      장타율
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.srate
                        ? parseFloat(data?.srate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      출루율
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {data?.obrate
                        ? parseFloat(data?.obrate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      도루성공률
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.sbrate
                        ? parseFloat(data?.sbrate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                </View>
                <View style={[pageStyle.row]}>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      멀티히트
                    </Text>
                    <TextInput
                      style={[pageStyle.td]}
                      numberOfLines={1}
                      value={data?.muitihit}
                      onChangeText={value => {
                        let temp = getCalculateData({
                          ...data,
                          muitihit: value.replace(/[^0-9]/g, ''),
                        });
                        setData({
                          ...data,
                          muitihit: value.replace(/[^0-9]/g, ''),
                          havg: temp.havg,
                          srate: temp.srate,
                          obrate: temp.obrate,
                          sbrate: temp.sbrate,
                          ops: temp.ops,
                          bbk: temp.bbk,
                          ohRate: temp.ohRate,
                          h: temp.h,
                          totalBaseHit: temp.totalBaseHit,
                        });
                      }}
                    />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      OPS
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.ops ? parseFloat(data?.ops).toFixed(3) : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      BB/K
                    </Text>
                    <Text style={[pageStyle.td]} numberOfLines={1}>
                      {data?.bbk ? parseFloat(data?.bbk).toFixed(3) : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1}>
                      장타/안타
                    </Text>
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}>
                      {data?.ohRate
                        ? parseFloat(data?.ohRate).toFixed(3)
                        : '0.000'}
                    </Text>
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1} />
                    <Text style={[pageStyle.td]} numberOfLines={1} />
                  </View>
                  <View style={[pageStyle.col]}>
                    <Text style={[pageStyle.th]} numberOfLines={1} />
                    <Text
                      style={[pageStyle.td, pageStyle.even]}
                      numberOfLines={1}
                    />
                  </View>
                </View>
              </>
            )}
          </View>
          <TouchableOpacity style={[pageStyle.btn]} onPress={handleSave}>
            <Text style={[pageStyle.btnTxt]}>저장</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  navBgImage: {
    marginStart: 0,
    position: 'absolute',
    width: Dimensions.get('window').width,
    height: '100%',
  },
  headerText: {color: 'white'},
  container: {
    paddingBottom: 80,
    height: '100%',
  },
  innerContainer: {
    paddingBottom: 45,
  },
  infoTxt: {
    marginTop: 20,
    color: 'red',
    fontSize: 14,
    paddingHorizontal: 24,
    fontFamily: 'Pretendard',
  },
  tab: {
    marginTop: 20,
    paddingHorizontal: 24,
    alignItems: 'center',
  },
  toggleContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toggleItem: {
    width: 42,
    height: 24,
    borderWidth: 1,
    borderColor: '#ccc',
    justifyContent: 'center',
    display: 'flex',
  },
  toggleLeft: {
    borderTopStartRadius: 12,
    borderBottomStartRadius: 12,
    borderEndWidth: 0,
  },
  toggleRight: {
    borderTopEndRadius: 12,
    borderBottomEndRadius: 12,
  },
  toggleLeftSelected: {
    border: 0,
    backgroundColor: Colors.main,
  },
  toggleRightSelected: {
    border: 0,
    backgroundColor: Colors.main,
  },
  toggleTxt: {
    textAlign: 'center',
    alignSelf: 'center',
    color: 'black',
    fontWeight: '600',
    fontSize: 12,
    fontFamily: 'Pretendard-SemiBold',
  },
  toggleTxtSelected: {
    color: 'white',
  },
  row: {
    flexDirection: 'row',
  },
  col: {
    flex: 1,
  },
  table: {
    marginTop: 18,
    paddingHorizontal: 24,
  },
  th: {
    backgroundColor: '#3f51b5',
    paddingVertical: 4,
    height: 22,
    lineHeight: 13,
    fontSize: 13,
    color: 'white',
    fontWeight: '700',
    fontFamily: 'Pretendard-Bold',
    textAlign: 'center',
  },
  td: {
    padding: 4,
    paddingVertical: 10,
    lineHeight: 15,
    fontSize: 13,
    color: 'black',
    fontWeight: '700',
    fontFamily: 'Pretendard-Bold',
    textAlign: 'center',
  },
  even: {
    backgroundColor: 'rgb(236, 236, 236)',
  },
  label: {
    width: 55,
    color: 'black',
    fontSize: 13,
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
    alignSelf: 'center',
  },
  value: {
    color: 'black',
    fontFamily: 'Pretendard',
    fontSize: 13,
    alignSelf: 'center',
  },
  btn: {
    backgroundColor: Colors.main,
    height: 50,
    marginHorizontal: 24,
    borderRadius: 22.5,
    marginTop: 36,
  },
  btnTxt: {
    color: 'white',
    alignSelf: 'center',
    fontFamily: 'Pretendard',
    fontSize: 18,
    lineHeight: 50,
  },
});

export default ExternalRecordView;
