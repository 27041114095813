import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Platform,
  DeviceEventEmitter,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import SelectDropdown from 'react-native-select-dropdown';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FlatList} from 'react-native-gesture-handler';
import moment from 'moment';
import PlayerCard from '../component/PlayerCard';
import {title} from '../common/util';

const PlayerCardListView = ({navigation, route}) => {
  const {userInfo, itemId} = route.params;

  const [myUserInfo, setMyUserInfo] = useState({});
  const [seasonYear, setSeasonYear] = useState(moment().format('YYYY'));
  const [subLeagueId, setSubLeagueId] = useState('');
  const [teamId, setTeamId] = useState('');
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [subLeagueOptions, setSubLeagueOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [progressShown, setProgressShown] = useState(false);

  const [representativeId, setRepresentativeId] = useState('');

  const [alarmUnread, setAlarmUnread] = useState(userInfo.alarmRead);
  const [page, setPage] = useState([]);

  let accessToken = useRef();
  let pageLoading = useRef();

  let seasonRef = useRef();
  let leagueSubLeagueRef = useRef();
  let teamRef = useRef();

  const getMyUserInfo = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get('/app-user/me', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setMyUserInfo(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getData = () => {
    if (!accessToken.current) {
      return;
    }
    if (pageLoading.current) {
      return;
    }
    setProgressShown(true);
    api
      .get(
        `/app-user/${itemId}/game-cards?seasonYear=${
          seasonYear ? seasonYear : ''
        }&teamId=${teamId ? teamId : ''}&subLeagueId=${
          subLeagueId ? subLeagueId : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setPage(response.data);
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].representative) {
            setRepresentativeId(response.data[i].id);
          }
        }
        setProgressShown(false);
      })
      .catch(function (error) {
        console.error(error);
        setProgressShown(false);
      })
      .finally(() => {
        pageLoading.current = false;
      });
  };

  const getMyTeams = token => {
    if (!token) {
      return;
    }
    let requestUri = `/app-team/my-teams?pageSize=100&uid=${itemId}`;
    if (subLeagueId) {
      requestUri += '&subLeagueId=' + subLeagueId;
    }
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const teamOptionCandidate = [];
        const teams = response.data.items;
        teamOptionCandidate.push({value: '', text: '전체 팀'});
        for (let i = 0; i < teams.length; i++) {
          teamOptionCandidate.push({value: teams[i].id, text: teams[i].name});
        }
        setTeamOptions(teamOptionCandidate);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const getMySubLeagues = token => {
    if (!token) {
      return;
    }
    let requestUri = `/app-user/my-sub-leagues?uid=${itemId}`;
    if (seasonYear && seasonYear !== '전체 시즌') {
      requestUri += `&seasonYear=${seasonYear}`;
    }
    if (teamId) {
      requestUri += `&teamId=${teamId}`;
    }
    api
      .get(requestUri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        const subLeagueOptionCandidate = [];
        const subLeagues = response.data;
        subLeagueOptionCandidate.push({value: '', text: '전체 조리그'});
        for (let i = 0; i < subLeagues.length; i++) {
          subLeagueOptionCandidate.push({
            value: subLeagues[i].id,
            text: `${subLeagues[i].league_name} - ${subLeagues[i].name}`,
          });
        }
        setSubLeagueOptions(subLeagueOptionCandidate);
      })
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const getSeasonList = () => {
    if (!accessToken.current) {
      return;
    }
    api
      .get(`app-user/my-seasons?uid=${itemId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        let seasonOptionList = [];
        seasonOptionList.push('전체 시즌');
        for (let i = 0; i < response.data.length; i++) {
          seasonOptionList.push(response.data[i].year);
        }
        setSeasonOptions(seasonOptionList);
      })
      .catch(function (error) {
        console.error(error.response);
        alert('요청에 실패했습니다.');
      });
  };

  const toggleRepresentative = item => {
    setRepresentativeId(item.id);
    const body = {
      targetUid: itemId,
      id: item.id,
    };
    api
      .post('/app-user/game-cards/representative', body, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        pageLoading.current = false;
      });
  };

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      '@playerCardRefresh',
      function (data) {
        getData();
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, []);

  useEffect(() => {
    if (seasonOptions.length > 1) {
      setSeasonYear(seasonOptions[1]);
    }
  }, [seasonOptions]);
  useEffect(() => {
    getData(accessToken.current);
    getMySubLeagues(accessToken.current);
  }, [seasonYear, subLeagueId, teamId]);
  useEffect(() => {
    getMySubLeagues(accessToken.current);
    setSubLeagueId('');
    setTeamId('');
    leagueSubLeagueRef.current.reset();
    teamRef.current.reset();
  }, [seasonYear]);
  useEffect(() => {
    getMyTeams(accessToken.current);
    teamRef.current.reset();
  }, [subLeagueId]);
  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getMyUserInfo();
      getData(result);
      getMyTeams(result);
      getSeasonList(result);
    });
  }, []);

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  const pageStyle = StyleSheet.create({
    headerCommon: {},
    navBgImage: {
      marginStart: 0,
      position: 'absolute',
      width: Platform.OS === 'web' ? fullWidth : Dimensions.get('window').width,
      height: '100%',
    },
    headerText: {color: 'white'},
    manageTeamBtn: {
      width: 60,
      height: 22,
      borderRadius: 12,
      borderWidth: 1,
      borderColor: 'white',
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
      marginEnd: 14,
    },
    container: {
      paddingBottom: 80,
      paddingHorizontal: 25,
    },
    list: {
      width: '100%',
    },
    row: {
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      borderBottomColor: '#e6e6e6',
      borderBottomWidth: 1,
      paddingVertical: 12,
      paddingHorizontal: 36,
    },
    icnImg: {
      width: 30,
      height: 40,
      marginEnd: 18,
    },
    txt: {
      fontFamily: 'Pretendard',
      fontSize: 17,
      color: 'black',
    },
    col: {
      width: '100%',
    },
    repContainer: {},
    repIcn: {
      opacity: 0.3,
      width: 16,
      height: 16,
    },
    repTxt: {
      color: '#333',
      fontFamily: 'Pretendard',
      fontSize: 14,
      marginStart: 6,
      opacity: 0.3,
    },
  });

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>선수 카드 목록</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              style={[styles.alarmContainer]}
              onPress={() => navigation.navigate('MyAlarmList')}>
              <Image
                resizeMode="contain"
                style={[styles.imgAlarm]}
                source={require('../assets/images/icn_alarm.png')}
              />
              {alarmUnread && <View style={[styles.alarmDot]} />}
            </TouchableOpacity>
          </View>
        </View>
      </Shadow>
      <View style={[pageStyle.container]}>
        <View style={[styles.row, styles.mt18]}>
          <View style={[styles.col, styles.mr7]}>
            <SelectDropdown
              ref={seasonRef}
              defaultValue={seasonYear}
              defaultButtonText="시즌"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={seasonOptions}
              onSelect={(selectedItem, index) => {
                if (selectedItem === '전체 시즌') {
                  setSeasonYear('');
                } else {
                  setSeasonYear(selectedItem);
                }
              }}
              renderDropdownIcon={() => (
                <Image source={require('../assets/images/select_arr.png')} />
              )}
            />
          </View>
          <View style={[styles.col]}>
            <SelectDropdown
              ref={teamRef}
              defaultValue={teamId}
              defaultButtonText="팀"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={teamOptions}
              onSelect={(selectedItem, index) => {
                if (selectedItem === '전체 팀') {
                  setTeamId('');
                } else {
                  setTeamId(selectedItem.value);
                }
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                return selectedItem.text;
              }}
              rowTextForSelection={(item, index) => {
                return item.text;
              }}
              renderDropdownIcon={() => (
                <Image source={require('../assets/images/select_arr.png')} />
              )}
            />
          </View>
        </View>
        <View style={[styles.row, styles.mt7]}>
          <View style={[styles.col]}>
            <SelectDropdown
              ref={leagueSubLeagueRef}
              defaultValue={subLeagueId}
              defaultButtonText="리그, 조"
              buttonStyle={styles.dropDown}
              buttonTextStyle={styles.downDownText}
              rowTextStyle={styles.dropDownRowText}
              data={subLeagueOptions}
              onSelect={(selectedItem, index) => {
                if (selectedItem === '전체 조리그') {
                  setSubLeagueId('');
                } else {
                  setSubLeagueId(selectedItem.value);
                }
              }}
              buttonTextAfterSelection={(selectedItem, index) => {
                return selectedItem.text;
              }}
              rowTextForSelection={(item, index) => {
                return `${item.text}`;
              }}
              renderDropdownIcon={() => (
                <Image source={require('../assets/images/select_arr.png')} />
              )}
            />
          </View>
        </View>
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled={true}
          style={[pageStyle.list]}
          keyExtractor={item => `free_${item.id.toString()}`}
          data={page}
          numColumns={2}
          renderItem={({item}) => (
            <TouchableOpacity
              style={[
                {
                  marginVertical: 12,
                },
              ]}
              key={`free_${item.id}`}
              onPress={() => {
                navigation.navigate('PlayerCardDetail', {
                  item: item,
                  userInfo: userInfo,
                  itemId: item.id,
                });
              }}>
              <PlayerCard
                item={item}
                userInfo={userInfo}
                margin={Platform.OS === 'web' ? 2.5 : 0}
                cardWidth={
                  Platform.OS === 'web' ? (fullWidth - 25 * 2 - 5 * 3) / 2 : 130
                }
              />
              {myUserInfo.id === userInfo.id && (
                <TouchableOpacity
                  onPress={() => toggleRepresentative(item)}
                  style={[
                    styles.row,
                    {marginTop: 6, justifyContent: 'center'},
                  ]}>
                  <Image
                    source={require('../assets/images/checked.png')}
                    style={[
                      pageStyle.repIcn,
                      representativeId === item.id && {opacity: 1.0},
                    ]}
                  />
                  <Text
                    style={[
                      pageStyle.repTxt,
                      representativeId === item.id && {
                        opacity: 1.0,
                        fontWeight: 'bold',
                        fontFamily: 'Pretendard-Bold',
                      },
                    ]}>
                    대표카드
                  </Text>
                </TouchableOpacity>
              )}
            </TouchableOpacity>
          )}
          ListFooterComponent={() => (
            <View style={[{height: Platform.OS == 'ios' ? 30 : 100}]} />
          )}
          ListEmptyComponent={() => (
            <View style={[styles.emptyContainer]}>
              <Image source={require('../assets/images/logo2.png')} />
            </View>
          )}
        />
      </View>
    </SafeAreaView>
  );
};

export default PlayerCardListView;
