import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Alert,
  DeviceEventEmitter,
  Platform,
  Modal,
} from 'react-native';
import styles from '../common/styles';
import {Shadow} from 'react-native-shadow-2';
import api from '../common/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import {launchImageLibrary} from 'react-native-image-picker';
import alert from '../component/alert';
import {title} from '../common/util';
import Colors from '../common/colors';

const MyAccountView = ({navigation, route}) => {
  const [userInfo, setUserInfo] = useState({});
  const [leaveInfoPopupOpened, setLeaveInfoPopupOpened] = useState(false);
  const [leaveRequested, setLeaveRequested] = useState(false);

  const [profileImageUri, setProfileImageUri] = useState('');
  const [profileImageData, setProfileImageData] = useState();

  let accessToken = useRef();
  const getUserInfo = () => {
    api
      .get('/app-user/me', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setUserInfo(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const birthFormatted = useMemo(() => {
    if (!userInfo || !userInfo.birth) {
      return '';
    }
    return moment(userInfo.birth, 'YYYY-MM-DD').format('YYYY년 MM월 DD일');
  }, [userInfo]);

  const profileImageUrl = useMemo(() => {
    if (userInfo?.profileImageThumbUrl) {
      return {uri: userInfo?.profileImageThumbUrl};
    }
    if (userInfo?.profileImageUrl) {
      return {uri: userInfo?.profileImageUrl};
    }
    return require('../assets/images/ic_camera_rounded_bg.png');
  }, [userInfo]);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getUserInfo();
    });
    DeviceEventEmitter.emit('userInfoRefresh', {});
  }, []);

  const unselectImage = () => {
    alert('프로필 이미지', '프로필 이미지를 삭제하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          setProfileImageUri(null);
          setProfileImageData(null);
          setUserInfo({
            ...userInfo,
            ['profileImageUrl']: null,
            ['profileImageThumbUrl']: null,
          });
          api
            .post(
              '/users/me/profile-image',
              {
                profileImageData: null,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken.current}`,
                },
              },
            )
            .then(function (response) {
              console.log(response);
              setTimeout(() => {
                DeviceEventEmitter.emit('userInfoRefresh', {});
              }, 1000);
            })
            .catch(function (error) {
              console.error(error);
            });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  const openImageLibrary = () => {
    launchImageLibrary(
      {
        mediaType: 'photo',
        includeBase64: true,
        maxHeight: 500,
        maxWidth: 500,
      },
      response => {
        if (
          !response.errorCode &&
          response.assets &&
          response.assets.length > 0 &&
          response.assets[0].base64
        ) {
          alert('프로필 이미지', '프로필 이미지를 변경하시겠습니까?', [
            {
              text: '예',
              onPress: () => {
                setProfileImageUri(response.assets[0].uri);
                setProfileImageData(
                  'data:image/png;base64,' + response.assets[0].base64,
                );
                setUserInfo({
                  ...userInfo,
                  ['profileImageThumbUrl']: response.assets[0].uri,
                });
                api
                  .post(
                    '/users/me/profile-image',
                    {
                      profileImageData:
                        'data:image/png;base64,' + response.assets[0].base64,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken.current}`,
                      },
                    },
                  )
                  .then(function (response) {
                    console.log(response);
                    setTimeout(() => {
                      DeviceEventEmitter.emit('userInfoRefresh', {});
                    }, 1000);
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
              },
            },
            {
              text: '아니오',
            },
          ]);
        }
      },
    );
  };

  const callLeaveApi = () => {
    api
      .delete('/users/me', {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setLeaveInfoPopupOpened(false);
        setLeaveRequested(true);
        alert('7일 뒤 데이터가 삭제됩니다.');
      })
      .catch(function (error) {
        console.error(error.response, error);
        alert('요청에 실패했습니다.');
      });
  };

  const cancelLeave = () => {
    Alert.alert('탈퇴 취소', '탈퇴 취소 하시겠습니까?', [
      {
        text: '예',
        onPress: () => {
          api
            .delete('/users/cancel-leave', {
              headers: {
                Authorization: `Bearer ${accessToken.current}`,
              },
            })
            .then(function (response) {
              alert('탈퇴 요청이 취소되었습니다.');
              setLeaveRequested(false);
            })
            .catch(function (error) {
              console.error(error.response, error);
              alert('요청에 실패했습니다.');
            });
        },
      },
      {
        text: '아니오',
      },
    ]);
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>내 계정</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]} />
        </View>
      </Shadow>
      <View style={[pageStyle.container]}>
        <View style={[pageStyle.profileContainer]}>
          <TouchableOpacity
            onPress={openImageLibrary}
            style={[pageStyle.profileInnerContainer]}>
            <Image
              source={profileImageUrl}
              style={[pageStyle.profileImage]}
              resizeMode={'cover'}
            />
          </TouchableOpacity>
          {(userInfo?.profileImageUrl || profileImageUri) && (
            <TouchableOpacity
              onPress={unselectImage}
              style={[{position: 'absolute', top: 0}]}>
              <Image
                source={require('../assets/images/close.png')}
                style={[
                  {
                    opacity: 0.4,
                    top: 40,
                    left: Dimensions.get('window').width / 2 + 50,
                  },
                ]}
              />
            </TouchableOpacity>
          )}
        </View>
        <View style={[pageStyle.row]}>
          <Text style={[pageStyle.subtitle]}>이름</Text>
          <Text style={[pageStyle.value]}>{userInfo.name}</Text>
        </View>
        <View style={[pageStyle.row]}>
          <Text style={[pageStyle.subtitle]}>성별</Text>
          <Text style={[pageStyle.value]}>{userInfo.gender}</Text>
        </View>
        <View style={[pageStyle.row]}>
          <Text style={[pageStyle.subtitle]}>생년월일</Text>
          <Text style={[pageStyle.value]}>{birthFormatted}</Text>
        </View>
        <View style={[pageStyle.row]}>
          <Text style={[pageStyle.subtitle]}>이메일아이디</Text>
          <Text style={[pageStyle.value]}>{userInfo.email}</Text>
        </View>
        <View style={[pageStyle.row]}>
          <Text style={[pageStyle.subtitle]}>전화번호</Text>
          <Text style={[pageStyle.value]}>{userInfo.cellphone}</Text>
        </View>
        <View style={[pageStyle.row]}>
          <View style={[styles.col]} />
          <View style={[styles.col]}>
            {!userInfo.leaveRequestAt && !leaveRequested && (
              <TouchableOpacity
                style={[pageStyle.leaveBtn]}
                onPress={() => setLeaveInfoPopupOpened(true)}>
                <Text style={[pageStyle.buttonTxt]}>회원탈퇴</Text>
              </TouchableOpacity>
            )}
            {(userInfo.leaveRequestAt || leaveRequested) && (
              <TouchableOpacity
                style={[pageStyle.leaveBtn]}
                onPress={cancelLeave}>
                <Text style={[pageStyle.buttonTxt]}>탈퇴취소</Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={[styles.col]} />
        </View>
      </View>
      {leaveInfoPopupOpened && (
        <Modal transparent={true} overFullScreen={true}>
          <View style={[pageStyle.popupContainer]}>
            <View style={[pageStyle.popupInnerContainer]}>
              <TouchableOpacity
                onPress={() => setLeaveInfoPopupOpened(false)}
                style={[{position: 'absolute', top: 0, right: 0}]}>
                <Image
                  source={require('../assets/images/close.png')}
                  style={[{opacity: 0.4}]}
                />
              </TouchableOpacity>
              <Text
                style={[
                  {
                    fontSize: 20,
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard-Bold',
                    textAlign: 'center',
                  },
                ]}>
                탈퇴하시겠습니까?
              </Text>
              <Text
                style={[
                  {
                    fontSize: 16,
                    fontFamily: 'Pretendard',
                    color: 'black',
                    textAlign: 'center',
                    marginTop: 8,
                  },
                ]}>
                기존의 가입정보와 기록이 7일 뒤 삭제됩니다.
              </Text>
              <View style={[styles.row, pageStyle.leaveButtonContainer]}>
                <TouchableOpacity
                  onPress={callLeaveApi}
                  style={[pageStyle.btn]}>
                  <Text style={[pageStyle.btnTxt]}>예</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => setLeaveInfoPopupOpened(false)}
                  style={[pageStyle.btn, pageStyle.btnActive]}>
                  <Text style={[pageStyle.btnTxt, pageStyle.btnActiveTxt]}>
                    아니오
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      )}
    </SafeAreaView>
  );
};

const pageStyle = StyleSheet.create({
  headerCommon: {},
  navBgImage: {
    marginStart: 0,
    position: 'absolute',
    width: Dimensions.get('window').width,
    height: '100%',
  },
  headerText: {color: 'white'},
  container: {
    paddingBottom: 80,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 36,
    justifyContent: 'space-between',
  },
  profileContainer: {
    position: 'relative',
  },
  profileInnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 42,
  },
  profileImage: {
    width: 120,
    height: 120,
    borderRadius: 60,
  },
  subtitle: {
    fontFamily: 'Pretendard',
    fontSize: 16,
    color: 'black',
  },
  value: {
    fontFamily: 'Pretendard',
    fontSize: 16,
    color: '#3f51b5',
  },
  commonBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 30,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  cancelBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 30,
    backgroundColor: '#aaa',
  },
  leaveBtn: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    height: 30,
    backgroundColor: 'red',
    opacity: 0.3,
  },
  buttonTxt: {
    fontFamily: 'Pretendard',
    fontSize: 15,
    color: 'white',
  },
  buttonSmallTxt: {
    fontFamily: 'Pretendard',
    fontSize: 10,
    color: 'white',
  },
  popupContainer: {
    zIndex: 100,
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popupInnerContainer: {
    backgroundColor: 'white',
    width: 300,
    marginHorizontal: Dimensions.get('window').width / 2 - 150,
    top: Dimensions.get('window').height / 2 - 150,
    textAlign: 'center',
    padding: 20,
    alignItems: 'center',
  },

  buttonContainer: {
    paddingHorizontal: 10,
  },
  leaveButtonContainer: {
    marginTop: 24,
    paddingHorizontal: 24,
  },
  btn: {
    marginHorizontal: 12,
    flex: 1,
    borderWidth: 1,
    borderColor: 'black',
    height: 30,
    borderRadius: 15,
  },
  btnTxt: {
    lineHeight: 28,
    fontSize: 16,
    fontFamily: 'Pretendard',
    textAlign: 'center',
    color: 'black',
  },
  btnActive: {
    backgroundColor: Colors.main,
  },
  btnActiveTxt: {
    color: 'white',
  },
});

export default MyAccountView;
