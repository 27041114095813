import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ImageBackground,
  Dimensions,
  Image,
  ScrollView,
  Linking,
  Platform,
} from 'react-native';
import GameMetaItem from '../GameMetaItem';
import BoardSummaryItem from '../BoardSummaryItem';
import Colors from '../../common/colors';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import styles from '../../common/styles';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Carousel from 'react-native-reanimated-carousel';
import ScaledImage from '../../component/ScaledImage';

const LeagueHome = ({itemId, tourMode, navigation}) => {
  const windowWidth = Dimensions.get('window').width;
  let accessToken = useRef();

  const [league, setLeague] = useState({});
  const [myLeagueIds, setMyLeagueIds] = useState([]);
  const [gamePage, setGamePage] = useState([]);
  const [boardPage, setBoardPage] = useState([]);

  const [banners, setBanners] = useState([]);

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getLeagueData();
      getData(result);
      getMyLeagueIds(result);
      getBanner();
    });
  }, []);

  const getBanner = () => {
    if (!accessToken.current) {
      return;
    }

    api
      .get(`/banners?leagueId=${itemId}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setBanners(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getLeagueData = token => {
    api
      .get(`/leagues/${itemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setLeague(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getData = token => {
    if (!itemId) {
      return;
    }
    api
      .get(`/app-league/leagues/${itemId}/games/today?pageSize=2`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setGamePage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    api
      .get(`/app-league/leagues/${itemId}/boards?pageSize=7`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setBoardPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMyLeagueIds = token => {
    if (!token) {
      return;
    }
    api
      .get('/app-league/my-league-ids', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setMyLeagueIds(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        // alert('요청에 실패했습니다.');
      })
      .finally(() => {});
  };

  const goToBoardDetail = boardItem => {
    navigation.navigate('BoardDetail', {
      item: boardItem,
      itemId: boardItem.id,
      leagueId: itemId,
      myAuthorities: league.myAuthorities,
      myLeagueIds: myLeagueIds,
      communityType: boardItem.communityType,
    });
  };

  const increateBannerHitCount = id => {
    api
      .post(
        `/banners/${id}/hit-count`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {})
      .catch(function (error) {
        console.error(error.response);
      });
  };

  const [carouselHeight, setCarouselHeight] = useState(150);

  const fullWidth = useMemo(() => {
    if (Platform.OS === 'web') {
      const windowWidth = Dimensions.get('window').width;
      if (windowWidth < 550) {
        return windowWidth;
      } else {
        return 550;
      }
    }
    return '100%';
  }, []);

  return (
    <ScrollView>
      <View style={[{paddingBottom: 120}]}>
        {league?.onlineNotice && (
          <View style={[pageStyle.noticeContainer]}>
            <Image
              source={require('../../assets/images/icn_notice.png')}
              style={[pageStyle.icn]}
            />
            <Text style={[pageStyle.noticeTxt]} numberOfLines={1}>
              {league?.onlineNotice}
            </Text>
          </View>
        )}
        <FlatList
          keyExtractor={item => `game_${itemId.toString()}`}
          data={gamePage.items}
          ListEmptyComponent={() => (
            <View style={[styles.emptyContainer]}>
              <Image source={require('../../assets/images/logo2.png')} />
            </View>
          )}
          renderItem={({item, index}) => (
            <GameMetaItem
              data={item}
              index={index}
              navigation={navigation}
              tourMode={tourMode}
            />
          )}
        />
        {banners && banners.length > 0 && (
          <Carousel
            loop
            width={windowWidth}
            height={carouselHeight}
            autoPlayInterval={4000}
            autoPlay={banners?.length > 1}
            data={banners}
            scrollAnimationDuration={5000}
            pagingEnabled={true}
            // onSnapToItem={(index) => console.log('current index:', index)}
            renderItem={({index}) => (
              <TouchableOpacity
                onPress={() => {
                  if (!banners[index].linkUrl) {
                    return;
                  }
                  increateBannerHitCount(banners[index].id);
                  Linking.openURL(banners[index].linkUrl);
                }}
                style={{
                  flex: 1,
                  justifyContent: 'center',
                }}>
                <ScaledImage
                  width={
                    Platform.OS === 'web'
                      ? fullWidth
                      : Dimensions.get('window').width
                  }
                  setHeight={setCarouselHeight}
                  uri={banners[index].imageUrl}
                />
              </TouchableOpacity>
            )}
          />
        )}
        <View style={[pageStyle.boardContainer]}>
          <View style={[pageStyle.boardTitleContainer]}>
            <Text style={[pageStyle.boardTitle]}>최근 게시물</Text>
          </View>
          <FlatList
            style={[pageStyle.boardList]}
            keyExtractor={(item, index) => `board_${index.toString()}`}
            data={boardPage.items}
            ListEmptyComponent={() => (
              <View style={[styles.emptyContainer]}>
                <Image source={require('../../assets/images/logo2.png')} />
              </View>
            )}
            renderItem={({item}) => (
              <TouchableOpacity onPress={() => goToBoardDetail(item)}>
                <BoardSummaryItem data={item} />
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default LeagueHome;

const pageStyle = StyleSheet.create({
  headerCommon: {},
  noticeContainer: {
    borderWidth: 1,
    height: 27,
    borderRadius: 13.5,
    marginVertical: 12,
    marginHorizontal: 22,
    borderColor: Colors.main,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  noticeTxt: {color: Colors.main},
  icn: {position: 'absolute', left: 15, top: 4},
  boardContainer: {
    borderWidth: 5,
    borderColor: '#eee',
  },
  boardTitleContainer: {
    borderWidth: 1,
    borderColor: '#eee',
  },
  boardTitle: {padding: 8},
  boardList: {padding: 12},
});
