import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  StyleSheet,
  Dimensions,
  DeviceEventEmitter,
  Platform,
} from 'react-native';
import BoardItem from '../BoardItem';
import styles from '../../common/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../../common/api';
import * as Progress from 'react-native-progress';
import Colors from '../../common/colors';
const Community = props => {
  const [boardPage, setBoardPage] = useState({});
  const [myLeagueIds, setMyLeagueIds] = useState([]);
  const [progressShown, setProgressShown] = useState(false);
  const [communityType, setCommunityType] = useState('전체글');
  const accessToken = useRef();
  let pageLoading = useRef();

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      'communityListRefresh',
      function (data) {
        getData(accessToken.current);
      },
    );
    return () => {
      unsubscribe.remove();
    };
  }, [props?.item.id]);

  const boardWritable = useMemo(() => {
    if (communityType === '리그 공지사항') {
      return (
        props.league.myAuthorities && props.league.myAuthorities.length > 0
      );
    }
    return myLeagueIds.includes(props.league.id);
  }, [communityType, props.league, myLeagueIds]);

  const getMyLeagueIds = token => {
    if (!token) {
      return;
    }
    api
      .get('/app-league/my-league-ids', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setMyLeagueIds(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      })
      .finally(() => {});
  };

  const getData = token => {
    let communityParam = communityType;
    if (communityParam == '전체글') {
      communityParam = '전체 게시판';
    } else if (communityParam == '공지사항') {
      communityParam = '리그 공지사항';
    } else if (communityParam == '기록수정') {
      communityParam = '기록수정게시판';
    }
    setProgressShown(true);
    api
      .get(
        `/app-league/leagues/${props.item.id}/boards?type=${
          communityParam && communityParam !== '전체 게시판'
            ? encodeURIComponent(communityParam)
            : ''
        }&pageNo=1&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(function (response) {
        setBoardPage(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        alert('요청에 실패했습니다.');
      })
      .finally(() => {
        setProgressShown(false);
      });
  };

  const goToWriteView = item => {
    props.navigation.navigate('BoardWrite', {
      item: null,
      leagueId: props.item.id,
      communityType: '리그 공지사항',
      admin:
        props.league.myAuthorities && props.league.myAuthorities.length > 0,
    });
  };

  const goToBoardDetail = item => {
    props.navigation.navigate('BoardDetail', {
      item: item,
      itemId: item.id,
      leagueId: props.item.id,
      myAuthorities: props.league.myAuthorities,
      myLeagueIds: myLeagueIds,
      communityType: communityType,
    });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      accessToken.current = result;
      getData(result);
      getMyLeagueIds(result);
    });
  }, []);

  useEffect(() => {
    getData(accessToken.current);
  }, [communityType]);

  const handlePageLoadMore = () => {
    if (pageLoading.current) {
      return;
    }
    if (boardPage.currentPage && boardPage.totalPage <= boardPage.currentPage) {
      return;
    }
    setProgressShown(true);
    pageLoading.current = true;

    let communityParam = communityType;
    if (communityParam == '전체글') {
      communityParam = '전체 게시판';
    } else if (communityParam == '공지사항') {
      communityParam = '리그 공지사항';
    } else if (communityParam == '기록수정') {
      communityParam = '기록수정게시판';
    }

    api
      .get(
        `/app-league/leagues/${props.item.id}/boards?type=${encodeURIComponent(
          communityParam,
        )}&pageNo=${
          boardPage.currentPage ? parseInt(boardPage.currentPage, 10) + 1 : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(boardPage.items ? boardPage.items : []),
          ...response.data.items,
        ];
        setBoardPage(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setProgressShown(false);
        pageLoading.current = false;
      });
  };
  return (
    <View style={[pageStyle.container]}>
      <FlatList
        showsHorizontalScrollIndicator={Platform.OS === 'web'}
        horizontal={true}
        ListHeaderComponent={() => <View style={[{width: 10}]} />}
        style={[pageStyle.tabContainer]}
        data={['전체글', '공지사항', '자유게시판', '기록수정']}
        renderItem={({item}) => {
          return (
            <TouchableOpacity
              onPress={() => setCommunityType(item)}
              style={[
                pageStyle.tabItem,
                communityType === item && pageStyle.tabItemSelected,
              ]}>
              <Text
                style={[
                  pageStyle.tabTxt,
                  communityType === item && pageStyle.tabTxtSelected,
                ]}>
                {item}
              </Text>
            </TouchableOpacity>
          );
        }}
      />
      <FlatList
        ListHeaderComponent={
          <View style={[pageStyle.titleContainer]}>
            <Text style={[pageStyle.title]}>
              {communityType ? communityType : '전체 게시판'}
            </Text>
          </View>
        }
        onEndReachedThreshold={1}
        onEndReached={handlePageLoadMore}
        style={[pageStyle.list]}
        keyExtractor={item => item.id.toString()}
        data={boardPage.items}
        renderItem={({item}) => (
          <TouchableOpacity
            onPress={() => {
              goToBoardDetail(item);
            }}>
            <BoardItem data={item} navigation={props.navigation} />
          </TouchableOpacity>
        )}
        ListEmptyComponent={() => (
          <View style={[styles.emptyContainer]}>
            <Image source={require('../../assets/images/logo2.png')} />
          </View>
        )}
      />
      {boardWritable && (
        <TouchableOpacity
          style={[pageStyle.writeBtnContainer]}
          onPress={goToWriteView}>
          <Image
            style={[pageStyle.writeIcn]}
            source={require('../../assets/images/icn_write.png')}
          />
        </TouchableOpacity>
      )}
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </View>
  );
};

export default Community;

const pageStyle = StyleSheet.create({
  headerCommon: {},
  container: {
    marginTop: 5,
    height:
      Dimensions.get('window').height - (Platform.OS === 'ios' ? 200 : 150),
  },
  writeBtnContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  writeIcn: {
    width: 80,
    height: 80,
  },
  tabContainer: {
    maxHeight: 35,
    height: 35,
    backgroundColor: '#ddd',
    paddingTop: 5,
  },
  tabItemContainer: {},
  tabItem: {
    paddingHorizontal: 24,
    paddingVertical: 3,
    height: 25,
    justifyContent: 'center',
  },
  tabItemSelected: {
    backgroundColor: Colors.main,
    borderRadius: 12.5,
  },
  tabTxt: {
    color: 'black',
    fontSize: 15,
  },
  tabTxtSelected: {
    color: 'white',
    fontWeight: 'bold',
  },
  titleContainer: {
    paddingHorizontal: 12,
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.15)',
  },
  title: {color: 'black', fontSize: 13},
  list: {
    width: '100%',
    display: 'flex',
    marginTop: 6,
    flex: 1,
  },
  communityMenuContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  communityItemMenuContainer: {
    backgroundColor: Colors.main,
    opacity: 0.9,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: 'rgba(255, 255, 255, 0.4)',
  },
  communityItemMenuTxt: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
  },
});
