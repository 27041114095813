import React, {useState, useEffect, useRef, useMemo} from 'react';
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  FlatList,
  TextInput,
  Platform,
  Dimensions,
  useWindowDimensions,
  Alert,
  DeviceEventEmitter,
  Linking,
} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import styles from '../common/styles';
import RenderHtml from 'react-native-render-html';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../common/api';
import * as Progress from 'react-native-progress';
import {useHeaderHeight} from '@react-navigation/elements';
import moment from 'moment';
import 'moment/locale/ko';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import alert from '../component/alert';
import {title} from '../common/util';
import Colors from '../common/colors';

const BoardDetailView = ({navigation, route}) => {
  const {item, itemId, leagueId, communityType, myAuthorities, myLeagueIds} =
    route.params;
  const [board, setBoard] = useState({});
  const [commentContents, setCommentContents] = useState({});
  const [childCommentContents, setChildCommentContents] = useState({});
  const [commentPage, setCommentPage] = useState({});
  const [userData, setUserData] = useState({});
  const [childCommentInputOpened, setChildCommentInputOpened] = useState();
  const {width} = useWindowDimensions();
  const [progressShown, setProgressShown] = useState(false);
  const accessToken = useRef();
  const commentContentsRef = useRef();
  const childCommentContentsRef = useRef();
  let pageLoading = useRef();

  const height = useHeaderHeight();
  const subscription = useRef();

  useEffect(() => {
    const unsubscribe = DeviceEventEmitter.addListener(
      'communityListRefresh',
      function (data) {
        if (data && data.id && data.id === itemId) {
          getData(accessToken.current);
        }
      },
    );

    return () => {
      unsubscribe.remove();
    };
  }, [item?.id]);

  const getData = token => {
    api
      .get(`/app-league/leagues/boards/${itemId}?fromUser=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setBoard(response.data);
        callGetCommentListApi();
      })
      .catch(function (error) {
        console.error('1', error);
      });
  };

  const callDeleteCommentListApi = id => {
    api
      .delete(`/boards/comments/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        callGetCommentListApi();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const callGetCommentListApi = () => {
    if (pageLoading.current) {
      return;
    }
    pageLoading.current = true;
    api
      .get(`/boards/${itemId}/comments`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        setCommentPage(response.data);
      })
      .catch(function (error) {
        console.error('2', error);
      })
      .finally(() => {
        pageLoading.current = false;
      });
  };

  const callWriteCommentApi = parentId => {
    api
      .post(
        `/boards/${board.id}/comments`,
        {
          parentId: parentId ? parentId : null,
          contents: parentId
            ? childCommentContentsRef.current
            : commentContentsRef.current,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        setCommentContents('');
        setChildCommentContents('');
        callGetCommentListApi();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    AsyncStorage.getItem('@accessToken', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      accessToken.current = result;
      getData(result);
    });
    AsyncStorage.getItem('@userInfo', (err, result) => {
      if (err) {
        console.error(err);
        return;
      }
      if (!result) {
        return;
      }
      setUserData(JSON.parse(result));
    });
  }, []);

  const deleteComment = id => {
    alert('댓글', '삭제 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callDeleteCommentListApi(id),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const writeComment = parentId => {
    if (!commentWritable) {
      return;
    }
    alert('댓글', '작성 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callWriteCommentApi(parentId),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const handlePageLoadMore = () => {
    if (!board.id) {
      return;
    }
    if (pageLoading.current) {
      return;
    }
    if (
      commentPage.currentPage &&
      commentPage.totalPage <= commentPage.currentPage
    ) {
      return;
    }
    setProgressShown(true);
    pageLoading.current = true;
    api
      .get(
        `boards/${board.id}/comments?pageNo=${
          commentPage.currentPage
            ? parseInt(commentPage.currentPage, 10) + 1
            : 1
        }&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
          },
        },
      )
      .then(function (response) {
        response.data.items = [
          ...(commentPage.items ? commentPage.items : []),
          ...response.data.items,
        ];
        setCommentPage(response.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setProgressShown(false);
        pageLoading.current = false;
      });
  };

  const callDeleteApi = () => {
    api
      .delete(`app-league/leagues/boards/${board.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then(function (response) {
        alert('요청에 성공하였습니다.');
        DeviceEventEmitter.emit('communityListRefresh');
        Platform.OS === 'web' ? history.back() : navigation.goBack();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const parseDate = date => {
    if (date.indexOf('T') > -1) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
    return moment(date, 'YYYY-MM-DD').locale('ko').format('MM/DD(dd)');
  };

  const toggleChildCommentInput = parentId => {
    setChildCommentInputOpened(parentId);
  };

  const handleDelete = () => {
    alert('게시글', '삭제 하시겠습니까?', [
      {
        text: '예',
        onPress: () => callDeleteApi(),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const handleModify = () => {
    alert('게시글', '수정 하시겠습니까?', [
      {
        text: '예',
        onPress: () =>
          navigation.navigate('BoardWrite', {
            item: board,
            leagueId: leagueId,
            admin: board.type === '리그 공지사항',
          }),
      },
      {
        text: '아니오',
      },
    ]);
  };

  const commentWritable = useMemo(() => {
    if (communityType === '리그 공지사항') {
      return myAuthorities && myAuthorities.length > 0;
    }
    return myLeagueIds?.includes(leagueId);
  }, [communityType, myLeagueIds, leagueId, myAuthorities]);

  const getProfileImageUrl = item => {
    if (item.user?.profileImageThumbUrl) {
      return {uri: item.user?.profileImageThumbUrl};
    }
    if (item.user?.profileImageUrl) {
      return {uri: item.user?.profileImageUrl};
    }
    return require('../assets/images/icn_profile_img.png');
  };

  return (
    <SafeAreaView nativeID="common-page-container" style={[styles.container]}>
      <Shadow
        offset={[0, 2]}
        style={[{width: '100%'}]}
        containerStyle={[{marginTop: 0}]}
        distance={7}
        sides={{bottom: true, top: false, start: false, end: false}}>
        <View style={[styles.headerCommon]}>
          <View style={[styles.row, styles.verticalAlign]}>
            <TouchableOpacity
              onPress={() =>
                Platform.OS === 'web' ? history.back() : navigation.goBack()
              }>
              <Image
                source={require('../assets/images/icon_back.png')}
                style={[styles.headerBackButton]}
              />
            </TouchableOpacity>
            <Text style={[styles.headerText]}>게시글 상세</Text>
          </View>
          <View style={[styles.row, styles.verticalAlign]}>
            <Image source={require('../assets/images/icon_uniqueplay.png')} />
          </View>
        </View>
      </Shadow>
      <View style={[styles.container]}>
        <View style={[pageStyle.commentContainer]}>
          <KeyboardAwareScrollView
            enableOnAndroid={true}
            enableAutomaticScroll={Platform.OS === 'ios'}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 40 : -10}
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
            <FlatList
              removeClippedSubviews={false}
              style={[pageStyle.list]}
              showsVerticalScrollIndicator={false}
              ListFooterComponent={() => <View style={[{height: 40}]} />}
              ListEmptyComponent={() => (
                <View style={[styles.emptyContainer]}>
                  <Image source={require('../assets/images/logo2.png')} />
                </View>
              )}
              ListHeaderComponent={() => (
                <View style={[pageStyle.headerContainer]}>
                  <View style={[styles.row, {justifyContent: 'space-between'}]}>
                    <Text style={[pageStyle.type]}>{board.type}</Text>
                  </View>
                  <Text style={[pageStyle.title]} multiLine={true}>
                    {board.title}
                  </Text>
                  <View style={[styles.row, pageStyle.row, styles.mt7]}>
                    <View style={[styles.row, pageStyle.center]}>
                      <Image
                        style={[
                          {
                            width: 30,
                            height: 30,
                            resizeMode: 'cover',
                            borderRadius: 15,
                          },
                        ]}
                        source={getProfileImageUrl(board)}
                      />
                      <View>
                        <Text style={[pageStyle.label]}>
                          {board.user?.name}
                        </Text>
                        <View
                          style={[pageStyle.center, styles.row, styles.mt3]}>
                          <Text style={[pageStyle.label]}>
                            {moment(
                              board.createdAt?.substring(
                                0,
                                board.createdAt?.indexOf('T'),
                              ),
                              'YYYY-MM-DD',
                            ).format('YYYY.MM.DD')}
                          </Text>
                          <View>
                            <View style={[styles.row, pageStyle.center]}>
                              <Text style={[pageStyle.label]}>조회수</Text>
                              <Text style={[pageStyle.hit]}>
                                {board.hitCount}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  {board.contents && (
                    <View style={[pageStyle.contentsContainer]}>
                      <RenderHtml
                        tagsStyles={contentHtmlStyles}
                        contentWidth={
                          Platform.OS === 'web'
                            ? 550
                            : Dimensions.get('window').width
                        }
                        source={{html: board.contents}}
                      />
                    </View>
                  )}

                  {userData?.id === board?.user?.id && (
                    <View style={[pageStyle.buttonContainer]}>
                      <View style={[{flex: 3}]} />
                      <TouchableOpacity
                        onPress={handleDelete}
                        style={[
                          pageStyle.buttonItem,
                          pageStyle.boardDeleteBtn,
                        ]}>
                        <Text
                          style={[
                            pageStyle.buttonItemTxt,
                            pageStyle.deleteItemTxt,
                          ]}>
                          삭제
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={handleModify}
                        style={[pageStyle.buttonItem, pageStyle.commonBtn]}>
                        <Text style={[pageStyle.buttonItemTxt]}>수정</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  <View style={[pageStyle.border]} />
                  {board?.fileList && (
                    <View style={[pageStyle.fileWrap]}>
                      <Text style={[pageStyle.fileSubTitle]}>첨부파일</Text>
                      {JSON.parse(board?.fileList)?.map((item, index) => (
                        <TouchableOpacity
                          style={[pageStyle.fileItemWrap]}
                          onPress={() => Linking.openURL(item.url)}>
                          <Text style={[pageStyle.fileItemTxt]}>
                            {item.name}
                          </Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  )}
                  <Text style={[pageStyle.commentSubtitle]}>
                    댓글{' '}
                    {commentPage?.totalCount
                      ? commentPage?.totalCount.toLocaleString()
                      : '0'}
                  </Text>
                  {commentWritable && (
                    <View style={[pageStyle.commentInputContainer]}>
                      <TextInput
                        // value={commentContents}
                        multiline={true}
                        style={[pageStyle.commentInput]}
                        placeholder={'댓글작성'}
                        placeholderTextColor={'#aaa'}
                        onChangeText={value =>
                          (commentContentsRef.current = value)
                        }
                      />
                      <TouchableOpacity
                        style={[pageStyle.commentBtn]}
                        onPress={() => writeComment()}>
                        <Text style={[pageStyle.commentBtnTxt]}>댓글입력</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              )}
              onEndReachedThreshold={1}
              onEndReached={handlePageLoadMore}
              nestedScrollEnabled={true}
              keyExtractor={item => itemId.toString()}
              data={commentPage.items}
              renderItem={({item}) => (
                <>
                  <View style={[pageStyle.commentItemContainer]}>
                    <Image
                      style={[
                        {
                          width: 30,
                          height: 30,
                          resizeMode: 'cover',
                          borderRadius: 15,
                        },
                      ]}
                      source={getProfileImageUrl(item)}
                    />

                    <View style={[pageStyle.rightContainer]}>
                      <Text style={[pageStyle.userName]}>{item.user.name}</Text>
                      <Text style={[pageStyle.commentContent]}>
                        {item.contents}
                      </Text>
                      <Text style={[pageStyle.date]}>
                        {parseDate(item.createdAt)}
                      </Text>
                    </View>
                    {item.user.id === userData.id && commentWritable && (
                      <TouchableOpacity
                        onPress={() => deleteComment(item.id)}
                        style={[pageStyle.deleteBtn]}>
                        <Image
                          style={[pageStyle.deleteImg]}
                          source={require('../assets/images/delete-outline.png')}
                        />
                      </TouchableOpacity>
                    )}
                    {commentWritable && (
                      <TouchableOpacity
                        onPress={() => toggleChildCommentInput(itemId)}
                        style={[pageStyle.replyBtn]}>
                        <Image source={require('../assets/images/reply.png')} />
                      </TouchableOpacity>
                    )}
                  </View>
                  <View>
                    {item.childs &&
                      item.childs.map((child, index) => {
                        return (
                          <View style={[pageStyle.commentItemContainer]}>
                            <View style={[pageStyle.childLeft]} />
                            <Image
                              style={[
                                {
                                  width: 30,
                                  height: 30,
                                  resizeMode: 'cover',
                                  borderRadius: 15,
                                },
                              ]}
                              source={getProfileImageUrl(child)}
                            />

                            <View style={[pageStyle.rightContainer]}>
                              <Text style={[pageStyle.userName]}>
                                {child.user.name}
                              </Text>
                              <Text style={[pageStyle.commentContent]}>
                                {child.contents}
                              </Text>
                              <Text style={[pageStyle.date]}>
                                {parseDate(child.createdAt)}
                              </Text>
                            </View>
                            {child.user.id === userData.id &&
                              commentWritable && (
                                <TouchableOpacity
                                  onPress={() => deleteComment(child.id)}
                                  style={[pageStyle.deleteBtn]}>
                                  <Image
                                    style={[pageStyle.deleteImg]}
                                    source={require('../assets/images/delete-outline.png')}
                                  />
                                </TouchableOpacity>
                              )}
                          </View>
                        );
                      })}
                  </View>
                  {childCommentInputOpened === itemId && (
                    <View style={[pageStyle.commentInputContainer]}>
                      <View style={[pageStyle.childLeft]} />
                      <TextInput
                        style={[pageStyle.commentInput]}
                        placeholder={'대댓글작성'}
                        placeholderTextColor={'#aaa'}
                        onChangeText={value =>
                          (childCommentContentsRef.current = value)
                        }
                      />
                      <TouchableOpacity
                        disabled={!commentWritable}
                        style={[pageStyle.commentBtn]}
                        onPress={() => writeComment(itemId)}>
                        <Text style={[pageStyle.commentBtnTxt]}>댓글입력</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </>
              )}
            />
          </KeyboardAwareScrollView>
        </View>
      </View>
      {progressShown && (
        <View style={[styles.progressContainer]}>
          <Progress.Circle
            fill="transparent"
            size={70}
            borderWidth={3}
            indeterminate={true}
          />
        </View>
      )}
    </SafeAreaView>
  );
};

export default BoardDetailView;

const contentHtmlStyles = StyleSheet.create({
  table: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: '#ccc',
    marginBottom: 7,
  },
  tr: {
    borderBottomWidth: 1,
    borderColor: '#ccc',
  },
  td: {
    borderRightWidth: 1,
    borderColor: '#ccc',
    padding: 5,
  },
  p: {
    marginTop: 3,
    marginBottom: 3,
  },
});

const pageStyle = StyleSheet.create({
  headerCommon: {},
  container: {
    borderWidth: 5,
    borderColor: '#ddd',
    opacity: 0.8,
    height: 200,
  },
  type: {
    color: Colors.main,
    fontSize: 15,
    fontFamily: 'Pretendard',
  },
  title: {
    paddingVertical: 4,
    fontSize: 16,
    fontFamily: 'Pretendard',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  headerContainer: {paddingHorizontal: 12},
  contentsContainer: {
    marginVertical: 8,
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    paddingVertical: 8,
  },
  border: {borderTopWidth: 1, borderTopColor: '#ddd'},
  fileWrap: {
    paddingVertical: 8,
  },
  fileSubTitle: {
    fontSize: 14,
    color: 'black',
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
    marginTop: 12,
    marginBottom: 4,
  },
  fileItemWrap: {
    paddingVertical: 2,
  },
  fileItemTxt: {
    fontSize: 13,
    color: 'black',
    fontWeight: '400',
    fontFamily: 'Pretendard-Medium',
  },
  commentContainer: {marginVertical: 12, paddingHorizontal: 8},
  commentSubtitle: {
    fontSize: 14,
    color: 'black',
    fontWeight: '600',
    fontFamily: 'Pretendard-SemiBold',
    marginTop: 12,
  },
  commentInputContainer: {flexDirection: 'row', marginTop: 8},
  commentInput: {
    flex: 1,
    height: 45,
    padding: 12,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  commentBtn: {
    height: 45,
    marginStart: 8,
    lineHeight: 45,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  commentBtnTxt: {
    height: 45,
    lineHeight: 45,
    fontSize: 12,
    fontFamily: 'Pretendard',
    textAlign: 'center',
    width: 55,
    color: 'black',
  },
  commentItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 12,
    paddingHorizontal: 8,
    flexDirection: 'row',
  },
  commentContent: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    color: 'black',
    marginTop: 4,
  },
  userName: {
    fontSize: 12,
    color: '#111',
    fontWeight: 'bold',
    fontFamily: 'Pretendard-Bold',
  },
  rightContainer: {
    marginStart: 8,
    flex: 1,
  },
  date: {
    fontSize: 11,
    fontFamily: 'Pretendard',
    color: '#aaa',
    marginTop: 8,
  },
  replyBtn: {
    opacity: 0.4,
    width: 20,
    justifyContent: 'flex-end',
  },
  deleteBtn: {
    opacity: 0.4,
    width: 20,
    justifyContent: 'flex-end',
  },
  replyImg: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
  },
  deleteImg: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
  },
  childLeft: {width: 40},
  list: {
    height:
      Dimensions.get('window').height - (Platform.OS === 'ios' ? 200 : 100),
  },
  buttonContainer: {
    marginTop: 25,
    flexDirection: 'row',
    marginVertical: 8,
  },
  buttonItem: {
    flex: 1,
    height: 30,
    alignItems: 'center',
    alignContent: 'center',
  },
  buttonItemTxt: {
    textAlign: 'center',
    fontSize: 13,
    fontFamily: 'Pretendard',
    color: 'white',
  },
  deleteItemTxt: {
    color: 'red',
  },
  commonBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 30,
    marginStart: 8,
    backgroundColor: 'rgb(61, 80, 183)',
  },
  boardDeleteBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 30,
    borderWidth: 1,
    borderColor: 'red',
    backgroundColor: 'white',
    opacity: 0.5,
  },
  label: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Pretendard',
    marginHorizontal: 6,
    lineHeight: 13,
  },
  hit: {color: 'black', fontSize: 11, fontFamily: 'Pretendard'},
});
